import { H2 } from 'Atoms/Typography/Headings/Heading';
import Modal from 'Organisms/Modal/Modal';
import { useEffect, useState } from 'react';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'stitches.config';
import SingleOptionCard from 'Organisms/SingleOptionCard/SingleOptionCard';
import MyGarageBikeModel from 'Models/MyGarage/MyGarageBikeModel.interface';
import Button from 'Atoms/Buttons/Button';
import { GetBikes, SetPrimaryBike } from 'Shared/MyGarage/MyGarage';
import { MotorcycleIcon } from 'Atoms/Icons';
import { useUserStateData } from 'Shared/Providers/UserContextProvider';
import { useFilterData } from 'Organisms/FilterComponent/FilterProvider';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import KexLink from 'Kex/KexLink';

type PropTypes = {
  showModal: boolean;
  setShowModal: (arg: boolean) => void;
};

const MyBikesModal = ({ showModal, setShowModal }: PropTypes) => {
  const {
    user: { primaryBike },
  } = useUserStateData();

  const {
    staticPages: { accountPage },
  } = useAppSettingsData();

  const [bikes, setBikes] = useState<MyGarageBikeModel[]>([]);

  const [, dispatch] = useFilterData();
  const [selected, setSelected] = useState<string>(primaryBike?.id);

  const { motorcycleModelFinderLabels, myGarageLabels } = useTranslationData();

  async function fetchBikes() {
    const res = await GetBikes();

    setBikes(res.models);
  }

  useEffect(() => {
    let hasMounted = true;

    if (hasMounted) {
      fetchBikes();
    }

    return () => {
      hasMounted = false;
    };
  }, []);

  const hasBikes = bikes.length > 0;

  return (
    <>
      <Modal
        showModal={showModal}
        toggle={() => setShowModal(!showModal)}
        noPadding
      >
        <ModalContent>
          <H2 css={{ mb: 8 }}>{motorcycleModelFinderLabels.selectYourBike}</H2>

          {bikes?.map((item, bikeIndex) => {
            return (
              <BikeItem key={item.modelId + bikeIndex}>
                <SingleOptionCard
                  content={
                    <BikeContent>
                      <MotorcycleIcon fillColor="primary" size="m" />
                      <BikeInnerContent>
                        <BikeName>{item.name}</BikeName>
                      </BikeInnerContent>
                    </BikeContent>
                  }
                  isActive={item.id === selected}
                  onClick={() => setSelected(item.id)}
                  //onClick={async () => setPrimaryBike(item.id)}
                />
              </BikeItem>
            );
          })}

          {!hasBikes && (
            <>
              {myGarageLabels.noBikesAdded}{' '}
              <KexLink
                href={accountPage}
                css={{ textDecoration: 'underline' }}
                underlined={false}
              >
                {' '}
                {myGarageLabels.myPages}
              </KexLink>
              {/* Du har inga hojar tillagda. Du kan lägga till din hoj i
              <KexLink href={accountPage} css={{ textDecoration: 'underline' }}>
                {' '}
                mina sidor.
              </KexLink> */}
            </>
          )}
        </ModalContent>

        {hasBikes && (
          <ModalCTA>
            <Button
              onClick={() => {
                dispatch({ type: 'setSelectedModel', value: '' });
                setShowModal(false);
              }}
              type="tertiary"
              fullWidth
            >
              {motorcycleModelFinderLabels.unselect}
            </Button>
            <Button
              onClick={async () => {
                if (!selected) {
                  return;
                }

                const res = await SetPrimaryBike(selected);

                dispatch({
                  type: 'setSelectedModel',
                  value: res.bike.modelId,
                });

                setShowModal(false);
              }}
              type="secondary"
              fullWidth
            >
              {motorcycleModelFinderLabels.showParts}
            </Button>
          </ModalCTA>
        )}
      </Modal>
    </>
  );
};

const BikeItem = styled('div', {
  mb: 4,
});

const BikeContent = styled('div', {
  backgroundColor: '$primary4',
  display: 'flex',
  gap: 16,
  width: '100%',
  p: 4,
  minHeight: 88,
});

const BikeInnerContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

const BikeName = styled('span', {
  fontFamily: '$fontSecondary600',
  fs: 10,
  lineHeight: '$lh12',
  textAlign: 'left',
});

const ModalCTA = styled('div', {
  display: 'flex',
  gap: 16,
  p: 4,
  position: 'absolute',
  bottom: 0,
  right: 0,
  left: 0,
  backgroundColor: '$primary4',
  '@mediaMinLarge': {
    px: 6,
    py: 8,
  },
});

const ModalContent = styled('div', {
  px: 8,
  height: '85vh',
  overflowY: 'auto',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    WebkitAppearance: 'none',
    display: 'none',
  },
  '&::scrollbar': {
    display: 'none',
  },
});

export default MyBikesModal;
