import { ProductType } from 'Enums/ProductType.enum';
import CategoryPageModel from 'Models/Pages/CategoryPage/CategoryPageModel.interface';
import ProductCardModel from 'Models/ProductCard/ProductCardModel.interface';
import Breadcrumb from 'Molecules/Breadcrumb/Breadcrumb';
import ContentContainer from 'Molecules/ContentContainer/ContentContainer';
import LoadMoreContainer from 'Molecules/LoadMoreContainer/LoadMoreContainer';
import BrandCardSection from 'Organisms/BrandCardSection/BrandCardSection';
import ProductCard from 'Organisms/ProductCard/ProductCard';
import FilterBar from 'Organisms/FilterComponent/FilterBar';

import { useQueryCategoryItems } from 'Pages/SearchPage/Search';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  canUseDOM,
  getBikeForLoggedOutUser,
  isEmpty,
  setBikeForLoggedOutUser,
} from 'Shared/Common/Helpers';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import useMedia from 'Shared/Hooks/useMedia';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'stitches.config';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import FitmentRibbon from './FitmentRibbon';
import {
  FilterProvider,
  useFilterData,
} from '../../Organisms/FilterComponent/FilterProvider';
import Button from 'Atoms/Buttons/Button';
import AddMotorcycleModal, {
  SelectedType,
} from 'Organisms/Modal/AddMotorcycleModal';
import MyBikesModal from './MyBikesModal';
import { useUserStateData } from 'Shared/Providers/UserContextProvider';
import { useKexLoadingCircle } from '../../Kex/KexLoadingCircle';
import SmallCategoryHero from './SmallCategoryHero';
const API_URL = 'GetCategoryItems';

function CategoryPageWrapper() {
  if (!canUseDOM()) {
    return <></>;
  }

  return (
    <FilterProvider
      urlSearchString={window?.location.search || ''}
      noQuery={true}
    >
      <CategoryPage />
    </FilterProvider>
  );
}

const CATEGORY_LOADING = 'CategoryPage[Initial Loading]';

function CategoryPage() {
  const { categoryLabels, motorcycleModelFinderLabels } = useTranslationData();

  const page = useCurrentPage();

  const {
    user: { authenticated },
  } = useUserStateData();

  const [{ selectedModel, queryParams }, dispatch] = useFilterData();

  const {
    pageHeading,
    pageIntroText,
    heroImage,
    inEditMode,
    heroDarkOverlay,
    brandCards,
    isSpareParts,
    isMotorcycles,
    isAccessories,
    pageTitle,
  } = useCurrentPage<CategoryPageModel>();

  const [showBikeSelector, setShowBikeSelector] = useState<boolean>(false);
  const [showExistingBikes, setShowExistingBikes] = useState<boolean>(false);
  const [loadingState, loadingDispatch] = useKexLoadingCircle();

  const [selectedBikeName, setSelectedBikeName] = useState<string>(() => {
    if (authenticated) {
      return '';
    }

    const selected = getBikeForLoggedOutUser();
    if (!selected) {
      return '';
    }
    return `${selected.brand} ${selected.modelName} ${selected.year}`;
  });
  const stateLoading = !!loadingState.has(CATEGORY_LOADING);

  const pagePath = page.breadcrumb[page.breadcrumb.length - 1]?.href || '';
  // skip the effect on did mount
  const didMountRef = useRef(false);
  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
    } else {
      dispatch({ type: 'onChangeCategory' });
    }
  }, [dispatch, pagePath]);

  // clean up
  useEffect(() => {
    return () => {
      loadingDispatch({
        type: 'remove',
        from: CATEGORY_LOADING,
      });
    };
  }, [loadingDispatch]);

  const { facets, sorters, isLoading, result, paginate, loadingMore } =
    useQueryCategoryItems(API_URL, queryParams, pagePath);

  useEffect(() => {
    if (isLoading && !stateLoading) {
      loadingDispatch({
        type: 'add',
        from: CATEGORY_LOADING,
      });
    } else if (!isLoading && stateLoading) {
      loadingDispatch({
        type: 'remove',
        from: CATEGORY_LOADING,
      });
    }
  }, [isLoading, stateLoading, loadingDispatch]);

  useEffect(
    () => facets && sorters && dispatch({ type: 'setFacets', facets, sorters }),
    [facets, sorters, dispatch]
  );

  const loadedPercentage = useMemo(() => {
    if (!result?.productSearchResult) {
      return 0;
    }

    return (
      (result.productSearchResult.items.length /
        result.productSearchResult.availableItems) *
      100
    );
  }, [result?.productSearchResult]);

  const counterText = useMemo(() => {
    return result?.productSearchResult
      ? categoryLabels.counter
          .replace('{0}', result.productSearchResult?.items.length.toString())
          .replace('{1}', result.productSearchResult?.availableItems.toString())
      : '';
  }, [result?.productSearchResult, categoryLabels.counter]);

  const setSelectedBike = (selected: SelectedType) => {
    setSelectedBikeName(
      `${selected.brand} ${selected.modelName} ${selected.year}`
    );

    setBikeForLoggedOutUser(selected);

    dispatch({
      type: 'setSelectedModel',
      value: selected.model,
    });
  };

  const productType =
    !isEmpty(result?.productSearchResult?.items) &&
    result?.productSearchResult?.items[0].productType;

  const clearModel = useCallback(() => {
    dispatch({ type: 'setSelectedModel', value: '' });
  }, [dispatch]);

  return (
    <>
      <FitmentRibbon
        name={selectedBikeName}
        clearModel={clearModel}
        onClick={() =>
          authenticated ? setShowExistingBikes(true) : setShowBikeSelector(true)
        }
      />

      <SmallCategoryHero
        header={pageHeading}
        pageIntroText={pageIntroText}
        inEditMode={inEditMode}
        image={heroImage}
        overlay={heroDarkOverlay}
      />

      <ContentContainer>
        <Breadcrumb />
      </ContentContainer>

      {!isEmpty(brandCards) && (
        <BrandCardSectionWrapper>
          <BrandCardSection
            brandCards={brandCards}
            backgroundColor="secondary"
          />
        </BrandCardSectionWrapper>
      )}

      <ContentContainer css={{ pb: 6 }}>
        {(isSpareParts || isAccessories) && (
          <>
            <Button
              type="secondary"
              icon={{ type: 'Add', color: 'secondaryDark', size: 'm' }}
              css={{ minWidth: 251, my: 12 }}
              onClick={() =>
                authenticated
                  ? setShowExistingBikes(true)
                  : setShowBikeSelector(true)
              }
            >
              {motorcycleModelFinderLabels.selectYourBike}
            </Button>
            {authenticated ? (
              <MyBikesModal
                showModal={showExistingBikes}
                setShowModal={setShowExistingBikes}
              />
            ) : (
              <AddMotorcycleModal
                showModal={showBikeSelector}
                setShowModal={setShowBikeSelector}
                handleAdd={setSelectedBike}
                buttonText={motorcycleModelFinderLabels.showParts}
              />
            )}
          </>
        )}

        {!!facets && !!result && (
          <FilterBar
            results={{
              facets: facets,
              productResults: result.productSearchResult,
            }}
            counterText={counterText}
            includesMotorcycles={isMotorcycles}
            pageTitle={pageTitle}
          />
        )}
      </ContentContainer>
      <ProductResults>
        <ContentContainer>
          {!isLoading ? (
            <>
              {productType === ProductType.MOTORCYCLES && (
                <MCStyledGrid>
                  {result?.productSearchResult?.items.map(
                    (product: ProductCardModel, index: number) => (
                      <MCProductCardWrapper key={index}>
                        <ProductCard item={product} />
                      </MCProductCardWrapper>
                    )
                  )}
                </MCStyledGrid>
              )}
              {productType !== ProductType.MOTORCYCLES && (
                <NonMCStyledGrid>
                  {result?.productSearchResult?.items.map((product, index) => (
                    <NonMCProductCardWrapper key={index}>
                      <ProductCard
                        item={product}
                        selectedModel={selectedModel}
                      />
                    </NonMCProductCardWrapper>
                  ))}
                </NonMCStyledGrid>
              )}
            </>
          ) : (
            <Padding />
          )}
        </ContentContainer>

        <LoadMoreContainerWrapper>
          {!isLoading && !!result && (
            <LoadMoreContainer
              loaded={loadedPercentage}
              counterText={counterText}
              loadMore={categoryLabels.loadMore}
              disabled={
                result.productSearchResult?.items.length ===
                result.productSearchResult?.availableItems
              }
              onClick={() => {
                paginate();

                dispatch({
                  type: 'setLoadedItems',
                  value: (
                    result.productSearchResult.items.length + 24
                  ).toString(),
                });
              }}
              isLoading={loadingMore}
            />
          )}
        </LoadMoreContainerWrapper>
      </ProductResults>
    </>
  );
}

const MCStyledGrid = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '16px',
  maxWidth: 'auto',
  '@media(min-width: 768px)': {
    gridTemplateColumns: '1fr 1fr',
    gap: '32px',
  },
  '@mediaMinLarge': {
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: '32px',
  },
});

const MCProductCardWrapper = styled('div', {
  minWidth: '220px',
});

const NonMCStyledGrid = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '16px',
  maxWidth: 'auto',
  '@media(min-width: 768px)': {
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '32px',
  },
  '@mediaMinLarge': {
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: '32px',
  },
});

const NonMCProductCardWrapper = styled('div', {
  minWidth: '140px',
});

const Padding = styled('div', {
  height: '400px',
});

const BrandCardSectionWrapper = styled('div', {
  pt: 8,
  pb: 4,
  '@mediaMinLarge': {
    pt: 12,
  },
});

const LoadMoreContainerWrapper = styled('div', {
  marginTop: '48px',
});

const ProductResults = styled('div', {
  backgroundColor: '$primary5',
  pt: 12,
});

export default CategoryPageWrapper;
