import ContentContainer from 'Molecules/ContentContainer/ContentContainer';
import { useUserStateData } from 'Shared/Providers/UserContextProvider';
import { styled } from 'stitches.config';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { CloseIcon } from 'Atoms/Icons';
import { useFilterData } from '../../Organisms/FilterComponent/FilterProvider';
import { useEffect } from 'react';
import sizes from 'Theme/Settings/sizes';

import { useStickyHeaderData } from 'Shared/Providers/StickyHeaderProvider';
import { animation, timings } from 'Theme/Settings/animation';

type PropType = {
  name: string;
  clearModel: () => void;
  onClick: () => void;
};

const FitmentRibbon = ({ name, clearModel, onClick }: PropType) => {
  const {
    user: { primaryBike },
  } = useUserStateData();

  const {
    motorcycleModelFinderLabels: { showingProductFitment },
  } = useTranslationData();
  const { shouldAnimateSticky } = useStickyHeaderData();
  const [{ selectedModel }] = useFilterData();

  useEffect(() => {
    if (!primaryBike && !name && selectedModel) {
      clearModel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!selectedModel) {
    return <></>;
  }

  return (
    <FitmentContainer sticky={shouldAnimateSticky}>
      <ContentContainer>
        <FitmentWrapper>
          <div>
            {showingProductFitment}:{' '}
            <FitmentBike onClick={onClick}>
              {name || primaryBike?.name}
            </FitmentBike>
          </div>
          <CloseButton onClick={clearModel}>
            <CloseIcon
              color="secondaryDark"
              size="s"
              fillColor="secondaryDark"
            />
          </CloseButton>
        </FitmentWrapper>
      </ContentContainer>
    </FitmentContainer>
  );
};

const FitmentContainer = styled('div', {
  top: 0,
  zIndex: '$Ribbon',
  backgroundColor: '$primaryLight2',
  fs: 6,
  lineHeight: '$lh133',
  color: '$primary6',
  position: 'sticky',
  transition: `all ${timings.oneFifth} ${animation.timingFn}`,
  py: 2,
  variants: {
    sticky: {
      true: {
        top: sizes.mobileHeaderHeight,
        '@mediaMinLarge': { top: sizes.desktopHeaderHeight },
      },
    },
  },
});

const FitmentWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
});

const FitmentBike = styled('span', {
  cursor: 'pointer',
  textDecoration: 'underline',
});
const CloseButton = styled('button', {});

export default FitmentRibbon;
