import { H1 } from 'Atoms/Typography/Headings/Heading';
import ImageModel from 'Models/Assets/ImageModel.interface';
import { applyEditModeAttr } from 'Shared/Common/Helpers';
import useMedia from 'Shared/Hooks/useMedia';
import { styled } from 'stitches.config';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import ContentContainer from 'Molecules/ContentContainer/ContentContainer';
import Paragraph from 'Atoms/Typography/Paragraph/Paragraph';

type HeroModuleType = {
  image: ImageModel;
  inEditMode?: boolean;
  header?: string;
  overlay?: boolean;
  pageIntroText?: string;
};

function SmallCategoryHero({
  header,
  inEditMode,
  image,
  overlay = false,
  pageIntroText,
}: HeroModuleType) {
  const isMobile = useMedia(mediaQueryTypes.mediaMaxMedium);

  return (
    <>
      {image && (
        <ImageContainer
          css={{
            backgroundImage: isMobile
              ? `url(${image.mobileSrc})`
              : `url(${image.src})`,
          }}
          overlay={overlay}
        />
      )}
      <ContentContainer width="content">
        <TextContainer noImage={!image}>
          <H1
            {...applyEditModeAttr(inEditMode ? 'HeroHeader' : '')}
            color={'tertiary'}
            css={{ m: 0 }}
          >
            {header}
          </H1>
          <ParagraphWrapper>
            <Paragraph size={isMobile ? 's' : 'l'}>{pageIntroText}</Paragraph>
          </ParagraphWrapper>
        </TextContainer>
      </ContentContainer>
    </>
  );
}

const ImageContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  color: '$heroTextPrimary',
  h: 54,
  '@mediaMinLarge': {
    h: 75,
  },
  variants: {
    overlay: {
      true: {
        '&:before': {
          position: 'absolute',
          content: '""',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: '$colors$backgroundPrimary',
          opacity: 0.4,
        },
      },
    },
  },
});

const ParagraphWrapper = styled('div', {
  mt: 4,
});

const TextContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  w: '100%',
  justifyContent: 'end',
  my: 12,
  h: '100%',
  '@mediaMinLarge': {
    mx: 0,
    maxW: 187,
    mb: 4,
    mt: 12,
  },
  variants: {
    noImage: {
      true: {
        '@mediaMinLarge': {
          mt: 30,
        },
      },
    },
  },
});

export default SmallCategoryHero;
